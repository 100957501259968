<template>
  <div>
    <messageInfo></messageInfo>
  </div>
</template>

<script>
import messageInfo from '../../components/messageInfo'
export default {
  components:{
    messageInfo
  }
}
</script>

<style>

</style>